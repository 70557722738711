import React from 'react'
import "../styles/home-banner.css"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import {Link} from 'gatsby'

function HomeBanner (props) {
const {video, introQuote, fallbackImage} = props

const background = imageUrlFor(buildImageObj(fallbackImage))
  .width(2000)
  .auto('format')
  .url()

//console.log(introQuote);

var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: true,
      speed: 1200,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000
    };

return (

  <div id="intro-holder">
    <div id="intro">
    <Link title="Alessandro Segalini" to="/about/">
      <div className="quote-holder">
        <div className="quote">
            <Slider {...settings}>
            {introQuote && introQuote.map(quote => (
              <div className="inner">
                <h2>“{quote.description}”</h2>
              </div>
            ))}
            </Slider>
        </div>
      </div>

      <div dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${video.asset.url}" type="video/mp4" />
        <source src="${background}" type="image/jpeg" />
        </video>`
      }}
      />

    </Link>
    </div>
  </div>

)}

export default HomeBanner