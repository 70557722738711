import {format} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getBlogUrl, getBlogCategoryUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import '../styles/blog-post-preview.css'

function BlogPostPreview (props) {

  //console.log(props);

  return (

    <div className="width-12-12-m width-6-12 writing-item">
      <h2><Link to={getBlogUrl(props.publishedAt, props.slug.current)}>{props.title}</Link></h2>
      <div className="date-tag-holder">
        <div class="date-holder">
          <span className="date">{format(props.publishedAt, 'MMMM d, YYYY')}</span>
        </div>

        {props.categories && (
          <div className="tags">
              {props.categories.map(category => (
                <Link className="tag" to={getBlogCategoryUrl(category.slug.current)}>{category.title}</Link>
              ))}
          </div>
        )}

      </div>

        {props.mainImage && props.mainImage.desktopImage && props.mainImage.desktopImage.asset && (
          <div className="image-holder">
            <Link to={getBlogUrl(props.publishedAt, props.slug.current)}>
            <picture>
              <source 
                media="(max-width: 699px)" 
                srcset={imageUrlFor(buildImageObj(props.mainImage.mobileImage))
                  .width(1000)
                  .height(Math.floor((1 / 1) * 1000))
                  .auto('format')
                  .url()}
              />
              <source 
                media="(min-width: 700px)" 
                srcset={imageUrlFor(buildImageObj(props.mainImage.desktopImage))
                  .width(2000)
                  .height(Math.floor((1 / 2) * 2000))
                  .auto('format')
                  .url()}
              />
              <img
                src={imageUrlFor(buildImageObj(props.mainImage.desktopImage))
                  .width(2000)
                  .height(Math.floor((1 / 2) * 2000))
                  .auto('format')
                  .url()}
                alt={props.mainImage.desktopImage.alt}
                title={props.mainImage.desktopImage.alt}
              />
            </picture>
            </Link>
            {props.mainImage.caption && (
              <span className="caption">
                {props.mainImage.caption}
              </span>
            )}

          </div>
        )}
      
      {props._rawExcerpt && (
        <div className="excerpt-holder">
          <PortableText blocks={props._rawExcerpt} />
        </div>
      )}
    </div>
  )
}

export default BlogPostPreview