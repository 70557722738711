import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutYears,
  filterOutCatsWithoutParents,
  shuffle
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import ProjectPreviewList from '../components/project-preview-list'
import BookPreviewList from '../components/book-preview-list'
import TypesettingPreviewList from '../components/typesetting-preview-list'
import FontPreviewList from '../components/font-preview-list'
import StudentWorkPreviewList from '../components/student-work-preview-list'
//import StudentWorkPreviewList from '../components/student-work-preview-list'
import Container from '../components/container'
import HomeBanner from '../components/home-banner'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }

    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }

    home: sanityHome(_id: { regex: "/(drafts.|)home/" }) {
      video {
        asset {
          url
        }
      }
      introQuote {
        description
      }
      fallbackImage {
        ...SanityImage
        alt
      }
    }

    posts: allSanityPost(
      limit: 2
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
            slug {
              current
            }
          }
          mainImage {
            desktopImage {
              ...SanityImage
              alt
              caption
            }
            mobileImage {
              ...SanityImage
              alt
              caption
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }

    projects: allSanityOtherProjects(
      limit: 5
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }, featured: { eq: "true" } }
    ) {
      edges {
        node {
          id
          publishedAt
          image {
            desktopImage {
              ...SanityImage
              alt
              caption
            }
            mobileImage {
              ...SanityImage
              alt
              caption
            }
          }
          images {
            ...SanityImage
            alt
            caption
          }
          title
          projectType
          _rawDescription
          slug {
            current
          }
        }
      }
    }

    books: allSanityBook(
      limit: 15
      sort: { fields: [catalogNumber], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          coverSpread {
            ...SanityImage
            alt
            caption
          }
          mainImage {
            ...SanityImage
            alt
            caption
          }
          spreads {
            ...SanityImage
            alt
            caption
          }
          title
          _rawDescription
          slug {
            current
          }
        }
      }
    }

    fonts: allSanityFont(
      limit: 10
      sort: { fields: title, order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          images {
            desktopImage {
              ...SanityImage
              alt
              caption
            }
            mobileImage {
              ...SanityImage
              alt
              caption
            }
          }
          title
          _rawDescription
          slug {
            current
          }
        }
      }
    }

    studentWork: allSanityStudentWork(
      limit: 6
      sort: { fields: [year], order: DESC }
      filter: { slug: { current: { ne: null } }, year: { ne: null } }
    ) {
      edges {
        node {
          id
          year
          images {
            ...SanityImage
            alt
            caption
          }
          title
          webLink
          name
          slug {
            current
          }
        }
      }
    }

  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const home = (data || {}).home
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const bookNodes = (data || {}).books
    ? mapEdgesToNodes(data.books)
      .filter(filterOutDocsWithoutSlugs)
    : []
  const fontNodes = (data || {}).fonts
    ? mapEdgesToNodes(data.fonts)
      .filter(filterOutDocsWithoutSlugs)
    : []
  const studentWorkNodes = (data || {}).studentWork
    ? mapEdgesToNodes(data.studentWork)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsWithoutYears)
    : []
  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

    //shuffle(bookNodes);
    shuffle(fontNodes);

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      <SEO
        title={site.title}
        image={site.image}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        
        {home.video && home.introQuote && home.fallbackImage &&  (
          <HomeBanner
            video={home.video}
            introQuote={home.introQuote}
            fallbackImage={home.fallbackImage}
          />
        )}

        {projectNodes && (
          <ProjectPreviewList
            title='Projects'
            nodes={projectNodes}
            browseMoreHref='/projects/'
          />
        )}

        {bookNodes && (
          <TypesettingPreviewList
            title='Typesetting'
            nodes={bookNodes}
            browseMoreHref='/books/'
          />
        )}

        {bookNodes && (
          <BookPreviewList
            title='Book Covers'
            nodes={bookNodes}
            browseMoreHref='/books/'
          />
        )}

        {fontNodes && (
          <FontPreviewList
            title='Fonts'
            nodes={fontNodes}
            browseMoreHref='/fonts/'
          />
        )}

        {studentWorkNodes && (
          <StudentWorkPreviewList
            title='Student work'
            nodes={studentWorkNodes}
            browseMoreHref='/student-work/'
          />
        )}

        {postNodes && (
          <BlogPostPreviewList
            title='Blog'
            nodes={postNodes}
            browseMoreHref='/blog/'
          />
        )}


      </Container>
    </Layout>
  )
}

export default IndexPage