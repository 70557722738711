import {Link} from 'gatsby'
import React from 'react'
import BlogPostPreview from './blog-post-preview'

import '../styles/blog-post-preview-list.css'

function BlogPostPreviewGrid (props) {
  return (
    <div className="blog-post-preview-list wrapper">
      {props.title && props.browseMoreHref && <h3 className="preview"><Link to={props.browseMoreHref}>{props.title}</Link></h3>}
      <div id="writings-holder" class="grid">
        {props.nodes &&
          props.nodes.map(node => (
              <BlogPostPreview {...node} />
          ))}
      </div>
    </div>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid